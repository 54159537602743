export const tripThemes = [
    {
        label: "Adventure",
        value: "Adventure"
    },
    {
        label: "Tour",
        value: "Tour"
    },
    {
        label: "Explore",
        value: "Explore"
    },
    {
        label: "Camping",
        value: "Camping"
    },
    {
        label: "Trek",
        value: "Trek"
    },
    {
        label: "Sight-seeing",
        value: "Sight-seeing"
    },
    {
        label: "Off-road",
        value: "Off-road"
    },
    {
        label: "Rafting",
        value: "Rafting"
    },
    {
        label: "Star Gazing",
        value: "Star Gazing"
    },
    {
        label: "Cycling",
        value: "Cycling"
    },
    {
        label: "Scuba Diving",
        value: "Scuba Diving"
    },
    {
        label: "Jet Ski",
        value: "Jet Ski"
    },
    {
        label: "Fishing",
        value: "Fishing"
    },
    {
        label: "Bagpacking",
        value: "Bagpacking"
    },
    {
        label: "Skiing",
        value: "Skiing"
    },
    {
        label: "Food Tourism",
        value: "Food Tourism"
    },
    {
        label: "Road Touring",
        value: "Road Touring"
    },
    {
        label: "Photography",
        value: "Photography"
    },
    {
        label: "Vlog",
        value: "Vlog"
    },
    {
        label: "Zip Lining",
        value: "Zip Lining"
    },
    {
        label: "Hiking",
        value: "Hiking"
    },
    {
        label: "Horseback Riding",
        value: "Horseback Riding"
    },
    {
        label: "Cultural Heritage",
        value: "Cultural Heritage"
    },
    {
        label: "Art & Craft",
        value: "Art & Craft"
    },
    {
        label: "CSR",
        value: "CSR"
    },
    {
        label: "Day Trip",
        value: "Day Trip"
    },
    {
        label: "Customized Tour",
        value: "Customized Tour"
    },
    {
        label: "Crabbing",
        value: "Crabbing"
    },
    {
        label: "Snorkeling",
        value: "Snorkeling"
    },
    {
        label: "Para sailing",
        value: "Para sailing"
    },
    {
        label: "Para gliding",
        value: "Para gliding"
    },
    {
        label: "Para motoring",
        value: "Para motoring"
    },
    {
        label: "Hot Air Baloon",
        value: "Hot Air Baloon"
    }
];

export const tripFacilities = [
    {
        label: "Air Tickets",
        value: "Air Tickets"
    },
    {
        label: "Travel Insurance",
        value: "Travel Insurance"
    },
    {
        label: "Camping Tents",
        value: "Camping Tents"
    },
    {
        label: "4x4 transport",
        value: "4x4 transport"
    },
    {
        label: "Luxury Transport",
        value: "Luxury Transport"
    },
    {
        label: "First-aid Kit",
        value: "First-aid Kit"
    },
    {
        label: "2-star Hotel",
        value: "2-star Hotel"
    },
    {
        label: "3-star Hotel",
        value: "3-star Hotel"
    },
    {
        label: "4-star Hotel",
        value: "4-star Hotel"
    },
    {
        label: "Joy ride Tickets",
        value: "Joy ride Tickets"
    },
    {
        label: "Breakfast",
        value: "Breakfast"
    },
    {
        label: "Hi-tea",
        value: "Hi-tea"
    },
    {
        label: "Brunch",
        value: "Brunch"
    },
    {
        label: "Chairlift",
        value: "Chairlift"
    },
    {
        label: "Local Guide",
        value: "Local Guide"
    },
    {
        label: "Lunch",
        value: "Lunch"
    },
    {
        label: "Dinner",
        value: "Dinner"
    },
    {
        label: "2 people sharing",
        value: "2 people sharing"
    },
    {
        label: "3 people sharing",
        value: "3 people sharing"
    },
];

export const tripRecommendedGear = [
    {
        label: "Hiking boots/Joggers/Comfortable Shoes/Slippers",
        value: "Hiking boots/Joggers/Comfortable Shoes/Slippers"
    },
    {
        label: "Standard domestic carry-on luggage size is 22” x 40” x 55” ",
        value: "Standard domestic carry-on luggage size is 22” x 40” x 55” "
    },
    {
        label: "T-shirts for day trekking",
        value: "T-shirts for day trekking"
    },
    {
        label: "1 x Jacket/Shawl/Sweater",
        value: "1 x Jacket/Shawl/Sweater"
    },
    {
        label: "Cap/Woolen Cap and Ear plug",
        value: "Cap/Woolen Cap and Ear plug"
    },
    {
        label: "Water bottle",
        value: "Water bottle"
    },
    {
        label: "Hand wash/soap/sanitizer, wipes, tooth paste and all other necessities",
        value: "Hand wash/soap/sanitizer, wipes, tooth paste and all other necessities"
    },
    {
        label: "Sun Block and Sun glasses",
        value: "Sun Block and Sun glasses"
    },
    {
        label: "Gloves (highly recommended)",
        value: "Gloves (highly recommended)"
    },
    {
        label: "Fleece / Sweaters",
        value: "Fleece / Sweaters"
    },
    {
        label: "Muffler (highly recommended)",
        value: "Muffler (highly recommended)"
    },
    {
        label: "Beanie (highly recommended)",
        value: "Beanie (highly recommended)"
    },
];

export const tripWhatsNotIncluded = [
    {
        label: "Any service which is not written in the 'services included' section",
        value: "Any service which is not written in the 'services included' section"
    },
    {
        label: "Medical Insurance",
        value: "Medical Insurance"
    },
    {
        label: "Lunch and Dinner",
        value: "Lunch and Dinner"
    },
    {
        label: "Guide or Porter or Hotel Staff tips",
        value: "Guide or Porter or Hotel Staff tips"
    },
    {
        label: "Any extra service occured during the trip",
        value: "Any extra service occured during the trip"
    },
    {
        label: "Entrance fee",
        value: "Entrance fee"
    },
    {
        label: "First Aid Kit",
        value: "First Aid Kit"
    },
    {
        label: "Gears for activities",
        value: "Gears for activities"
    },
];

export const tripTypes = [
    {
        label: "Group",
        value: "Group"
    },
    {
        label: "Customized",
        value: "Customized"
    },
    {
        label: "Activity",
        value: "Activity"
    },
];

export const tripRegions = [
    {
        label: "KPK",
        value: "KPK"
    },
    {
        label: "Gilgit Baltistan",
        value: "Gilgit Baltistan"
    },
    {
        label: "Azad Kashmir",
        value: "Azad Kashmir"
    },
    {
        label: "Punjab",
        value: "Punjab"
    },
    {
        label: "Sindh",
        value: "Sindh"
    },
    {
        label: "Balochistan",
        value: "Balochistan"
    },
    {
        label: "International",
        value: "International"
    },
];

export const tripStartingLocations = [
    {
        label: "Karachi",
        value: "Karachi"
    },
    {
        label: "Islamabad",
        value: "Islamabad"
    },
    {
        label: "Lahore",
        value: "Lahore"
    }
];

export const tripLocations = [
    {
        label: "Nathia Gali",
        value: "Nathia Gali"
    },
    {
        label: "Manora Island",
        value: "Manora Island"
    },
    {
        label: "Gilgit",
        value: "Gilgit"
    },
    {
        label: "Skardu",
        value: "Skardu"
    },
    {
        label: "Neelum Valley",
        value: "Neelum Valley"
    },
    {
        label: "Astore",
        value: "Astore"
    },
    {
        label: "Deosai",
        value: "Deosai"
    },
    {
        label: "Hunza",
        value: "Hunza"
    },
    {
        label: "Naltar Valley",
        value: "Naltar Valley"
    },
    {
        label: "Arang Kel",
        value: "Arang Kel"
    },
    {
        label: "Sharda",
        value: "Sharda"
    },
    {
        label: "Muree",
        value: "Muree"
    },
    {
        label: "Naran",
        value: "Naran"
    },
    {
        label: "Sharan",
        value: "Sharan"
    },
    {
        label: "Shogran",
        value: "Shogran"
    },
    {
        label: "Babusar Top",
        value: "Babusar Top"
    },
    {
        label: "Thandiani",
        value: "Thandiani"
    },
    {
        label: "Swat",
        value: "Swat"
    },
    {
        label: "Bahrain",
        value: "Bahrain"
    },
    {
        label: "Kalam",
        value: "Kalam"
    },
    {
        label: "Khanpur Dam",
        value: "Khanpur Dam"
    },
    {
        label: "Turbela",
        value: "Turbela"
    },
    {
        label: "Shanga Manga",
        value: "Shanga Manga"
    },
    {
        label: "Zaiarat",
        value: "Zaiarat"
    },
    {
        label: "Quetta",
        value: "Quetta"
    },
    {
        label: "Gorah Hill",
        value: "Gorah Hill"
    },
    {
        label: "Moola Chatok",
        value: "Moola Chatok"
    },
    {
        label: "Thatta",
        value: "Thatta"
    },
    {
        label: "Ormara",
        value: "Ormara"
    },
    {
        label: "Gwadar",
        value: "Gwadar"
    },
    {
        label: "Soon Valley",
        value: "Soon Valley"
    },
    {
        label: "Chitral",
        value: "Chitral"
    },
    {
        label: "Kelash",
        value: "Kelash"
    },
    {
        label: "Kund Malir",
        value: "Kund Malir"
    },
    {
        label: "Moola Chootak",
        value: "Moola Chootak"
    },
    {
        label: "Chota Chootak",
        value: "Chota Chootak"
    },
    {
        label: "Nathia Gali Beach",
        value: "Nathia Gali Beach"
    },
    {
        label: "Turtle Beach",
        value: "Turtle Beach"
    },
    {
        label: "Sapat Beach",
        value: "Sapat Beach"
    },
    {
        label: "Sandspit Beach",
        value: "Sandspit Beach"
    },
    {
        label: "Astola Island",
        value: "Astola Island"
    },
    {
        label: "Mubarak Village",
        value: "Mubarak Village"
    },
    {
        label: "Bhit Khori Beach",
        value: "Bhit Khori Beach"
    },
    {
        label: "Daam Beach",
        value: "Daam Beach"
    },
    {
        label: "French Beach",
        value: "French Beach"
    },
    {
        label: "Ziarat",
        value: "Ziarat"
    },
    {
        label: "Malam Jabba",
        value: "Malam Jabba"
    },
    {
        label: "Balakot",
        value: "Balakot"
    },
    {
        label: "Katas Raaj Temple",
        value: "Katas Raaj Temple"
    },
    {
        label: "Soon Valley",
        value: "Soon Valley"
    },
    {
        label: "Mushkpuri Top",
        value: "Mushkpuri Top"
    },
    {
        label: "Peer Chanasi",
        value: "Peer Chanasi"
    },
    {
        label: "Makli Necropolis",
        value: "Makli Necropolis"
    },
    {
        label: "Thatta",
        value: "Thatta"
    },
    {
        label: "Sehwan Shareef",
        value: "Sehwan Shareef"
    },
    {
        label: "Ranikot Fort",
        value: "Ranikot Fort"
    },
    {
        label: "Bhawalpur",
        value: "Bhawalpur"
    },
    {
        label: "Mabali Island",
        value: "Mabali Island"
    },
    {
        label: "Khanpur Dam",
        value: "Khanpur Dam"
    },
    {
        label: "Tarbela Dam",
        value: "Tarbela Dam"
    },
    {
        label: "Rawal Lake",
        value: "Rawal Lake"
    },
    {
        label: "Rawat Fort",
        value: "Rawat Fort"
    },
    {
        label: "Thar Parker",
        value: "Thar Parker"
    },
    {
        label: "Nagar Parker",
        value: "Nagar Parker"
    },
    {
        label: "Saidpur Shareef",
        value: "Saidpur Shareef"
    },
    {
        label: "Taxila",
        value: "Taxila"
    },
    {
        label: "Nankana Sahib Gurdwara",
        value: "Nankana Sahib Gurdwara"
    },
    {
        label: "Wagah Border",
        value: "Wagah Border"
    },
    {
        label: "Lahore",
        value: "Lahore"
    },
    {
        label: "Islamabad",
        value: "Islamabad"
    },
    {
        label: "Karachi",
        value: "Karachi"
    },
    {
        label: "Rawal Pindi",
        value: "Rawal Pindi"
    },
    {
        label: "Kot Diji Fort",
        value: "Kot Diji Fort"
    },
    {
        label: "Lahore",
        value: "Lahore"
    },
    {
        label: "Batakundi",
        value: "Batakundi"
    },
    {
        label: "Besham",
        value: "Besham"
    },
    {
        label: "Phander valley",
        value: "Phander valley"
    },
    {
        label: "Multan",
        value: "Multan"
    },
    {
        label: "Naltar Valley",
        value: "Naltar Valley"
    },
    {
        label: "Fairy Meadows",
        value: "Fairy Meadows"
    },
    {
        label: "Keran",
        value: "Keran"
    },
    {
        label: "Muzaffarad",
        value: "Muzaffarad"
    },
    {
        label: "Hingol National Park",
        value: "Hingol National Park"
    },
    {
        label: "Deosai National Park",
        value: "Deosai National Park"
    },
    {
        label: "Kirthar National Park",
        value: "Kirthar National Park"
    },
    {
        label: "Dudipatsar",
        value: "Dudipatsar"
    },
    {
        label: "Minimarg",
        value: "Minimarg"
    },
    {
        label: "Sukkur",
        value: "Sukkur"
    },
];

export const tripStatus = [
    {
        label: "Draft",
        value: "draft",
    },
    {
        label: "In Review",
        value: "in review",
    },
    {
        label: "Published",
        value: "published",
    },
    {
        label: "Expired",
        value: "expired",
    }
];

export const cancPolicy = `<ul className="list-unstyled">
<li>75% amount will be refunded 1 week before the trip </li>
<li> 50% amount will be refunded 4 days before the trip </li>
<li> Full amount will be charged 3 days before the trip in case of cancellation </li>
<li> 100% amount will be refunded in case of cancellation of the trip by the management </li>
<li> Services Charges are non-refundable in any of the above cases</li>
</ul>`;

export const locationTypes = [
    {
        label: 'City',
        value: 'City',
    },
    {
        label: 'Province',
        value: 'Province',
    },
    { 
        label: 'Town',
        value: 'Town',
    },
    { 
        label: 'Village',
        value: 'Village',
    },
    { 
        label: 'Continent',
        value: 'Continent',
    },
    { 
        label: 'Country',
        value: 'Country',
    },
    { 
        label: 'Island',
        value: 'Island',
    },
    { 
        label: 'Hill Station',
        value: 'Hill Station',
    },
];