import React, { Component } from "react";
import axios from "axios";
import swal from 'sweetalert';
import { vendors } from "../../../config/routes/server";

class AddVendor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                name: "",
                companyName: "",
                email: "",
                primaryPhone: "",
                secondaryPhone: "",
                cnic: "",
                ntn: "",
                facebook: "",
                instagram: "",
                bankAccountTitle: "",
                bankAccountNo: "",
                bankAccountBranch: "",
                city: "",
                country: ""
            },
            isEdit: false,
            id: "",
        };
    }

    componentDidMount() {
        if(this.props.match.params.id) {
            this.setState({
                id: this.props.match.params.id
            }, () => {
                axios
                    .get(`${vendors}/${this.state.id}`)
                    .then(response => {
                        this.setState({
                            data: response.data,
                            isEdit: true
                        });
                    })
                    .catch(error => {
                        swal(error.response.statusText, error.response.data.error, "error");
                    })
            });
        }
    }

    handleChange = (e) => {
        if(e.target.type === "checkbox") {
            this.setState({
                data: {
                    ...this.state.data,
                    [e.target.name]: e.target.checked
                }
            });
        }
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: e.target.value
            }
        });
    }

    
    handleSubmit = (e) => {
        e.preventDefault();

        // Conditional Post - Put 
        let url = "";
        if(this.state.isEdit) {
            url = `${vendors}/${this.state.id}`;
        }
        else {
            url = vendors;
        }

        let payload = this.state.data;
        if(this.state.isEdit) {
            axios
                .put(url, payload)
                .then(response => {
                    swal("Success", response.data.success, "success");
                })
                .catch(error => {
                    swal(error.response.statusText, error.response.data.error, "error")
                });
        }
        else {
            axios
                .post(url, payload)
                .then(response => {
                    swal("Success", response.data.success, "success");
                })
                .catch(error => {
                    swal(error.response.statusText, error.response.data.error, "error")
                });
        }
    }

    render() {
        const { name, companyName, email, primaryPhone, secondaryPhone, 
            cnic, ntn, facebook, instagram, bankAccountBranch,
            bankAccountNo, bankAccountTitle, city, country} = this.state.data;
        return (
            <form>
                <h3 className="lead text-center m-5">{this.state.isEdit ? "Edit" : "Add"} Vendor</h3>
                <div className="form-group">
                    <label>Vendor Name</label>
                    <input 
                        name="name" 
                        className="form-control" 
                        value={name}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Company Name</label>
                    <input 
                        name="companyName" 
                        className="form-control" 
                        value={companyName}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <input 
                        name="email" 
                        className="form-control" 
                        value={email}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Primary Phone</label>
                    <input 
                        name="primaryPhone" 
                        className="form-control" 
                        value={primaryPhone}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Secondary Phone</label>
                    <input 
                        name="secondaryPhone" 
                        className="form-control" 
                        value={secondaryPhone}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>NIC</label>
                    <input 
                        name="cnic" 
                        className="form-control" 
                        value={cnic}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>NTN</label>
                    <input 
                        name="ntn" 
                        className="form-control" 
                        value={ntn}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Facebook</label>
                    <input 
                        name="facebook" 
                        className="form-control" 
                        value={facebook}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Instagram</label>
                    <input 
                        name="instagram" 
                        className="form-control" 
                        value={instagram}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Bank Account Title</label>
                    <input 
                        name="bankAccountTitle" 
                        className="form-control" 
                        value={bankAccountTitle}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Bank Account No#</label>
                    <input 
                        name="bankAccountNo" 
                        className="form-control" 
                        value={bankAccountNo}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Bank Account Branch</label>
                    <input 
                        name="bankAccountBranch" 
                        className="form-control" 
                        value={bankAccountBranch}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>City</label>
                    <input 
                        name="city" 
                        className="form-control" 
                        value={city}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Country</label>
                    <input 
                        name="country" 
                        className="form-control" 
                        value={country}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="text-right">
                    <button 
                        className="btn w-25 btn-outline-success"
                        onClick={this.handleSubmit}>Submit</button>
                </div>
            </form>
        )
    }
}

export default AddVendor;