import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { home } from "../../../config/routes/server";

function Home() {
    const [customers, setCustomers] = useState("");
    const [groupTrips, setGroupTrips] = useState("");
    const [bookings, setBooking] = useState("");
    const [vendors, setVendors] = useState("");

    useEffect(() => {
        axios.get(home)
            .then(response => {
                setCustomers(response.data.customers);
                setGroupTrips(response.data.groupTrips);
                setBooking(response.data.bookings);
                setVendors(response.data.vendors);
            })
            .catch(error => {
                swal(error.response.statusText, error.response.data.error, "error");
            });
    }, []);

    return (
        <div className="container">
            <div className="row mb-3">
                <div className="col-sm-12 col-md-3">
                    <div className="card m-2">
                        <div className="card-body">
                            <h3>Customers</h3>
                            <p>{customers}</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-3">
                    <div className="card m-2">
                        <div className="card-body">
                            <h3>Group Trips</h3>
                            <p>{groupTrips}</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-3">
                    <div className="card m-2">
                        <div className="card-body">
                            <h3>Bookings</h3>
                            <p>{bookings}</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-3">
                    <div className="card m-2">
                        <div className="card-body">
                            <h3>Vendors</h3>
                            <p>{vendors}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-sm-12 col-lg-8 m-2">
                    <h4>Top Selling Trips</h4>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Trip</th>
                                <th scope="col">Total Sold</th>
                                <th scope="col">Link</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">1</th>
                                <td>Trip to Naran</td>
                                <td>80</td>
                                <td>@mdo</td>
                            </tr>
                            <tr>
                                <th scope="row">2</th>
                                <td>Trip to Nathia Gali & Ayubia</td>
                                <td>65</td>
                                <td>@fat</td>
                            </tr>
                            <tr>
                                <th scope="row">3</th>
                                <td>Trip to Abottabad & Thandiyani</td>
                                <td>53</td>
                                <td>@twitter</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Home;