import React, { Component } from "react";
import axios from "axios";
import swal from 'sweetalert';
import { Link } from "react-router-dom";
import qs from "qs";
import { locations } from "../../../config/routes/server";
import ClientRoute from "../../../config/routes/helper";

const columns = [
    {
        Header: "Title",
    },
    {
        Header: "Latitude",
    },
    {
        Header: "Longitude",
    },
    {
        Header: "Region",
    },
    {
        Header: "Type",
    },
    {
        Header: "Featured",
    },
    {
        Header: "Local",
    },
    {
        Header: "Active",
    },
    {
        Header: "Actions",
    }
];

class GetLocations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            meta: {},
            page: 1,
        }
    }

    componentDidMount() {
        this.getLocations();
    }

    getLocations = (query) => {
        let url = `${locations}/admin`;
        let pagination = {size: null, page: this.state.page}
        url += "?" + qs.stringify(pagination);

        if(query) {
            url += "&" + qs.stringify(query);
        }
        axios
            .get(url)
            .then(response => {
                this.setState({
                    data: response.data.locations,
                    meta: response.data.meta
                });
            })
            .catch(error => {
                swal(error.response.statusText, error.response.data.error, "error")
            });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handlePagination = (page) => {
        this.setState({ page: page }, () => this.getLocations());
    }

    render() {
        const { data, meta } = this.state;
        const pagination = meta.total > 10 ? (
            <div className="btn-toolbar" role="toolbar" aria-label="pagination">
                <div className="btn-group mr-2" role="group" aria-label="First group">
                    <button 
                        type="button" 
                        className="btn btn-info"
                        onClick={() => this.handlePagination(1)}>1</button>
                    <button 
                        type="button" 
                        className="btn btn-info"
                        onClick={() => this.handlePagination(2)}>2</button>
                    <button 
                        type="button" 
                        className="btn btn-info"
                        onClick={() => this.handlePagination(3)}>3</button>
                    <button 
                        type="button" 
                        className="btn btn-info"
                        onClick={() => this.handlePagination(4)}>4</button>
                </div>
            </div>
        ) : null;
        return (
            <>
                <h1 className="text-center">Locations</h1>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                {columns.map((column, index) => (
                                    <th scope="col" key={index}>{column.Header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.map(item => (
                                <tr key={item._id}>
                                    <th scope="row">
                                        <Link to={ClientRoute(`locations/${item._id}`)}>{item.title}</Link>
                                    </th>
                                    <td>{item.mapLat}</td>
                                    <td>{item.mapLong}</td>
                                    <td>{item.region.title}</td>
                                    <td>{item.type}</td>
                                    <td>{item.isFeatured ? "Yes" : "No"}</td>
                                    <td>{item.isLocal ? "Yes": "No"}</td>
                                    <td>{item.isActive ? "Yes": "No"}</td>
                                    <td><Link to={ClientRoute(`locations/${item._id}/images`)}>Upload Images</Link></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {pagination}
                </div>
            </>
        )
    }
}

export default GetLocations;