import React from "react";
import Navbar from "./Nav";

export default function Layout(props) {
    return (
        <div>
            <Navbar {...props} />
            <div className="container mt-5 mb-5">
                {props.children}
            </div>
        </div>
    );
}