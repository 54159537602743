import React, { Component } from "react";

class Addon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edComponent: [{}]
        };
    }

    componentDidMount() {
        if(this.props.data && this.props.data.length > 0) {
            this.setState({
                edComponent: this.props.data
            });
        }
    }

    renderAddon = (item, index) => (
        <div key={index} className="educationComponent">
            <p className="text-primary">Addon #{index + 1}
            {index > 0 && (
                <span 
                    onClick={() => this.removeEdComponent(index)}
                    style={{float: "right", color: "#000", cursor: "pointer",}}>x</span>
            )}
            </p>
            <div className="form-row">
                <div className="col-sm-12 col-lg-6">
                    <div className="form-group">
                        <label>Title</label>
                        <input 
                            type="text"
                            name="title" 
                            value={this.state.edComponent[index].title} 
                            className="form-control"
                            onChange={(e) => this.handleChange(e, index)}
                        />
                    </div>
                </div>
                <div className="col-sm-12 col-lg-6">
                    <div className="form-group">
                        <label>Price</label>
                        <input 
                            type="text"
                            name="price" 
                            value={this.state.edComponent[index].price} 
                            className="form-control"
                            onChange={(e) => this.handleChange(e, index)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    addEdComponent = (e) => {
        e.preventDefault();
        let edComps = this.state.edComponent;
        edComps.push({});
        this.setState({
            edComponent: edComps
        });
    }
    removeEdComponent = (index) => {
        let edComps = this.state.edComponent;
        edComps.splice(index,1);
        this.setState({
            edComponent: edComps
        }, () => this.props.pushData(this.state.edComponent));
    }

    handleChange = (e, index) => {
        let data = this.state.edComponent;
        data[index] = {
            ...data[index],
            [e.target.name]: e.target.value
        };
        this.setState({
            edComponent: data
        }, () => this.sendData());
    }

    sendData = () => {
        this.state.edComponent.forEach(each => {
            if(each.title && each.title.length > 0 
                && each.price && each.price.length > 0) {
                this.props.pushData(this.state.edComponent);
            }
        });
    }

    render() {
        return (
            <div>
                {this.state.edComponent 
                    && this.state.edComponent.map((each, index) => this.renderAddon(each, index))}
                <div className="text-right">
                    <button 
                        className="btn btn-outline-success" 
                        onClick={this.addEdComponent}>New Addon</button>
                </div>
            </div>
        );
    }
}

export default Addon;