import React, { Component } from "react";
import axios from "axios";
import swal from 'sweetalert';
import RSelect from "react-select";
import RCreateableSelect from "react-select/creatable";

import { locations, regions } from "../../../config/routes/server";
import { locationTypes } from "../../../config/variables/trip";

class AddLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                title: "",
                mapLat: "",
                mapLong: "",
                region: {},
                spots: [],
                isFeatured: false,
                isLocal: true,
                type: {},
                desc: "",
                isActive: true,
                isStartingLocation: false,
            },
            regions: [],
            isEdit: false,
            id: "",
        };
    }

    componentDidMount() {
        if(this.props.match.params.id) {
            this.setState({
                id: this.props.match.params.id
            }, () => {
                axios
                    .get(`${locations}/admin/${this.state.id}`)
                    .then(response => {
                        let data = response.data;
                        let spots = [];
                        data.spots.map(each => spots.push({ label: each, value: each}));
                        data.spots = spots;
                        data.region = { label: data.region.title, value: data.region._id };
                        data.type = { label: data.type, value: data.type };
                        this.setState({
                            data: response.data,
                            isEdit: true
                        });
                    })
                    .catch(error => {
                        swal(error.response.statusText, error.response.data.error, "error");
                    });
            });
        }
        this.getRegions();
    }

    handleChange = (e) => {
        if(e.target.type === "checkbox") {
            this.setState({
                data: {
                    ...this.state.data,
                    [e.target.name]: e.target.checked
                }
            });
        }
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSelect = (e, name) => {
        this.setState({
            data: {
                ...this.state.data,
                [name]: e
            }
        });
    }

    getRegions = () => {
        axios
            .get(regions)
            .then(response => {
                let regionsArr = [];
                response.data.regions.map(each =>
                    regionsArr.push({
                        label: each.title,
                        value: each._id,
                    }));
                this.setState({
                    regions: regionsArr,
                });
            })
            .catch(error => {
                swal(error.response.statusText, error.response.data.error, "error");
            });
    }
    
    handleSubmit = (e) => {
        e.preventDefault();

        // Conditional Post - Put 
        let url = "";
        if(this.state.isEdit) {
            url = `${locations}/${this.state.id}`;
        }
        else {
            url = locations;
        }

        let payload = this.state.data;
        payload.region = payload.region.value;
        payload.type = payload.type.value;
        payload.spots = payload.spots.map(each => each.value);
        if(this.state.isEdit) {
            axios
                .put(url, payload)
                .then(response => {
                    swal("Success", response.data.success, "success");
                })
                .catch(error => {
                    swal(error.response.statusText, error.response.data.error, "error")
                });
        }
        else {
            axios
                .post(url, payload)
                .then(response => {
                    swal("Success", response.data.success, "success");
                })
                .catch(error => {
                    swal(error.response.statusText, error.response.data.error, "error")
                });
        }
    }

    render() {
        const { title, mapLat, mapLong, region, spots, 
            isFeatured, type, isLocal, desc, isStartingLocation, } = this.state.data;
        return (
            <form>
                <h3 className="lead text-center m-5">{this.state.isEdit ? "Edit" : "Add"} Location</h3>
                <div className="form-group">
                    <label>Title</label>
                    <input 
                        name="title" 
                        className="form-control" 
                        value={title}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Map Latitude</label>
                    <input 
                        name="mapLat" 
                        className="form-control" 
                        value={mapLat}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Map Longitude</label>
                    <input 
                        name="mapLong" 
                        className="form-control" 
                        value={mapLong}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Region</label>
                    <RSelect 
                        options={this.state.regions}
                        value={region}
                        onChange={e => this.handleSelect(e, "region")}
                    />
                </div>
                <div className="form-group">
                    <label>Spots</label>
                    <RCreateableSelect 
                        isMulti
                        closeMenuOnSelect={false}
                        options={[]}
                        value={spots}
                        onChange={e => this.handleSelect(e, "spots")}
                    />
                </div>
                <div className="form-group">
                    <label>Type</label>
                    <RSelect 
                        options={locationTypes}
                        value={type}
                        onChange={e => this.handleSelect(e, "type")}
                    />
                </div>
                <div className="form-group">
                    <label>Description</label>
                    <textarea 
                        className="form-control"
                        name="desc"
                        value={desc}
                        onChange={this.handleChange}></textarea>
                </div>
                <div className="form-check">
                    <input 
                        name="isFeatured"
                        id="isFeatured"
                        className="form-check-input" 
                        type="checkbox" 
                        checked={isFeatured} 
                        value={isFeatured} 
                        onChange={this.handleChange}
                    />
                    <label className="form-check-label" htmlFor="isFeatured">Mark Featured</label>
                </div>
                <div className="form-check">
                    <input 
                        name="isLocal"
                        id="isLocal"
                        className="form-check-input" 
                        type="checkbox" 
                        checked={isLocal} 
                        value={isLocal} 
                        onChange={e => this.setState({ data: { ...this.state.data, isLocal: e.target.checked } })}
                    />
                    <label className="form-check-label" htmlFor="isLocal">Is the location local?</label>
                </div>
                <div className="form-check">
                    <input 
                        name="isStartingLocation"
                        id="isStartingLocation"
                        className="form-check-input" 
                        type="checkbox" 
                        checked={isStartingLocation} 
                        value={isStartingLocation} 
                        onChange={e => this.setState({ data: { ...this.state.data, isStartingLocation: e.target.checked } })}
                    />
                    <label className="form-check-label" htmlFor="isLocal">Is this starting location?</label>
                </div>
                <div className="text-right">
                    <button 
                        className="btn w-25 btn-outline-success"
                        onClick={this.handleSubmit}>Submit</button>
                </div>
            </form>
        )
    }
}

export default AddLocation;