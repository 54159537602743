import React from "react";
import { NavLink as Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../config/redux/actions/auth";
import ClientRoute from "../../../config/routes/helper";

function Navbar(props) {
    // const getNavLinkClass = (path) => {
    //     return props.location.pathname === path ? "nav-item active" : "nav-item";
    // }
    const onLogout = (e) => {
        e.preventDefault();
        setTimeout(() => {
            props.logoutUser();
        })
    }
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
                <Link className="navbar-brand" to={ClientRoute("")}>Dashboard</Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item dropdown">
                            <a 
                                className="nav-link dropdown-toggle" 
                                href="!#" id="navbarDropdown1" 
                                role="button" 
                                data-toggle="dropdown" 
                                aria-haspopup="true" 
                                aria-expanded="false">
                                Add
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown1">
                                <Link className="dropdown-item" to={ClientRoute("add-trip")}>Add Trip</Link>
                                <Link className="dropdown-item" to={ClientRoute("add-vendor")}>Add Vendor</Link>
                                <Link className="dropdown-item" to={ClientRoute("add-region")}>Add Region</Link>
                                <Link className="dropdown-item" to={ClientRoute("add-location")}>Add Location</Link>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a 
                                className="nav-link dropdown-toggle" 
                                href="!#" id="navbarDropdown1" 
                                role="button" 
                                data-toggle="dropdown" 
                                aria-haspopup="true" 
                                aria-expanded="false">
                                Data
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown1">
                                <Link className="dropdown-item" to={ClientRoute("trips")}>Trips</Link>
                                <Link className="dropdown-item" to={ClientRoute("bookings")}>Bookings</Link>
                                <Link className="dropdown-item" to={ClientRoute("users")}>Users</Link>
                                <Link className="dropdown-item" to={ClientRoute("vendors")}>Vendors</Link>
                                <Link className="dropdown-item" to={ClientRoute("customized")}>Customized</Link>
                                <Link className="dropdown-item" to={ClientRoute("regions")}>Regions</Link>
                                <Link className="dropdown-item" to={ClientRoute("locations")}>Locations</Link>
                            </div>
                        </li>
                        <li className="nav-item">
                            <a href="!#" onClick={onLogout} className="nav-link">Sign Out</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

Navbar.propTypes = {
    logoutUser: PropTypes.func,
    auth: PropTypes.object,
};

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Navbar);