import React, { Component } from "react";
import RSelect from "react-select";
import RCreateableSelect from "react-select/creatable";
import qs from "qs";
import CKEditor from "ckeditor4-react";
import axios from "axios";
import DayPicker, { DateUtils } from 'react-day-picker';
import swal from 'sweetalert';
import _ from "lodash";
import moment from "moment";
import 'react-day-picker/lib/style.css';
import Addons from "./Addons";

import { addTrip, vendors } from "../../../config/routes/server";
import {
    tripFacilities, 
    tripThemes, 
    tripRecommendedGear, 
    tripWhatsNotIncluded,
    tripTypes,
    tripRegions,
    tripStartingLocations,
    tripLocations,
    tripStatus,
    cancPolicy
} from "../../../config/variables/trip";
import ClientRoute from "../../../config/routes/helper";

class AddTrip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: {
                general: true,
                itinerary: false,
                addons: false,
                availableDates: false,
                services: false
            },
            data: {
                title: "",
                capacity: "",
                duration: "",
                region: {},
                type: {},
                isFeatured: false,
                startingLocation: {},
                price: "",
                discount: "",
                vendor: {},
                locations: [],
                theme: [],
                facilities: [],
                whatsNotIncluded: [],
                recommendedGear: [],
                desc: "",
                cancellationPolicy: cancPolicy,
                itinerary: "",
                dates: [],
            },
            vendors: [],
            isEdit: false,
            id: "",
            slug: "",
        };
        this.handleDayClick = this.handleDayClick.bind(this);
    }

    componentDidMount() {
        if(this.props.match.params.id) {
            this.setState({
                id: this.props.match.params.id
            }, () => {
                axios
                    .get(`${addTrip}/${this.state.id}/admin`)
                    .then(response => {
                        let region, type, startingLocation, vendor, status,
                            locations = [], facilities = [], whatsNotIncluded = [], 
                            recommendedGear = [], theme = [], dates = [];
                        region = {
                            label: response.data.region,
                            value: response.data.region,
                        };
                        type = {
                            label: response.data.type,
                            value: response.data.type,
                        };
                        startingLocation = {
                            label: response.data.startingLocation,
                            value: response.data.startingLocation,
                        };
                        vendor = {
                            label: response.data.vendor.companyName,
                            value: response.data.vendor._id,
                        };
                        status = {
                            label: _.capitalize(response.data.status),
                            value: response.data.status
                        };
                        response.data.locations.map(item => 
                            locations.push({
                                label: item,
                                value: item
                            })
                        );
                        response.data.theme.map(item => 
                            theme.push({
                                label: item,
                                value: item
                            })
                        );
                        response.data.whatsNotIncluded.map(item => 
                            whatsNotIncluded.push({
                                label: item,
                                value: item
                            })
                        );
                        response.data.recommendedGear.map(item => 
                            recommendedGear.push({
                                label: item,
                                value: item
                            })
                        );
                        response.data.facilities.map(item => 
                            facilities.push({
                                label: item,
                                value: item
                            })
                        );
                        response.data.dates.map(item => 
                            dates.push(new Date(item))
                        );
                        this.setState({
                            data: {
                                ...response.data,
                                region: region,
                                type: type,
                                startingLocation: startingLocation,
                                vendor: vendor,
                                locations: locations,
                                facilities: facilities,
                                whatsNotIncluded: whatsNotIncluded,
                                theme: theme,
                                recommendedGear: recommendedGear,
                                dates: dates,
                                status: status
                            },
                            isEdit: true,
                            slug: response.data.slug,
                        });
                    })
                    .catch(error => {
                        console.log(error)
                        swal(error.response.statusText, error.response.data.error, "error");
                    })
            });
        }
        this.getVendors();
    }

    getVendors = (query) => {
        let url = vendors;
        const pagination = {size: 0, page: 1}
        url += `?${qs.stringify(pagination)}`;

        if(query) {
            url += `&${qs.stringify(query)}`;
        }
        axios
            .get(url)
            .then(response => {
                const array = [];
                response.data.vendors.forEach(item => {
                    array.push({
                        label: item.companyName,
                        value: item._id
                    });
                })
                this.setState({
                    vendors: array,
                });
            })
            .catch(error => {
                swal(error.response.statusText, error.response.data.error, "error")
            });
    }

    handleChange = (e) => {
        if(e.target.type === "checkbox") {
            this.setState({
                data: {
                    ...this.state.data,
                    [e.target.name]: e.target.checked
                }
            });
        }
        else {
            this.setState({
                data: {
                    ...this.state.data,
                    [e.target.name]: e.target.value
                }
            });
        }
    }

    handleSelect = (e, name) => {
        this.setState({
            data: {
                ...this.state.data,
                [name]: e
            }
        });
    }

    handleView = (id) => {
        if(id === 1) {
            this.setState({
                view: {
                    general: true,
                    itinerary: false,
                    addons: false,
                    availableDates: false,
                    services: false,
                }
            });
        }
        if(id === 2) {
            this.setState({
                view: {
                    general: false,
                    itinerary: true,
                    addons: false,
                    availableDates: false,
                    services: false,
                }
            });
        }
        if(id === 3) {
            this.setState({
                view: {
                    general: false,
                    itinerary: false,
                    addons: true,
                    availableDates: false,
                    services: false,
                }
            });
        }
        if(id === 4) {
            this.setState({
                view: {
                    general: false,
                    itinerary: false,
                    addons: false,
                    availableDates: true,
                    services: false,
                }
            });
        }
        if(id === 5) {
            this.setState({
                view: {
                    general: false,
                    itinerary: false,
                    addons: false,
                    availableDates: false,
                    services: true,
                }
            });
        }
    }

    handleEditor = (e, name) => {
        this.setState({
            data: {
                ...this.state.data,
                [name]: e
            }
        });
    }

    handleDayClick(day, { selected }) {
        const { dates } = this.state.data;
        if (selected) {
          const selectedIndex = dates.findIndex(selectedDay =>
            DateUtils.isSameDay(selectedDay, day)
          );
          dates.splice(selectedIndex, 1);
        } else {
          dates.push(day);
        }
        this.setState({ 
            data: {
                ...this.state.data,
                dates
            }
        });
    }

    getAddons = (data) => {
        this.setState({
            data: {
                ...this.state.data,
                addons: data
            }
        }, () => console.log("Addons", this.state.data.addons));
    }

    handleStatus = (e, name) => {
        this.setState({
            data: {
                ...this.state.data,
                [name]: e
            }
        }, () => {
            const payload = {};
            payload.status = this.state.data.status.value;
            const url = `${addTrip}/${this.state.id}`;
            axios
                .put(url, payload)
                .then(response => {
                    swal("Success", response.data.success, "success");
                })
                .catch(error => {
                    swal(error.response.statusText, error.response.data.error, "error")
                });
        });
    }

    markEveryday = (e) => {
        e.preventDefault();
        const newDates = [];
        let currentDate = new Date(moment());
        const stopDate = new Date(moment().add(1, "year"));
        while(currentDate <= stopDate) {
            newDates.push(new Date(currentDate));
            currentDate = new Date(moment(currentDate).add(1, "d"));
        }
        this.setState({ 
            data: {
                ...this.state.data,
                dates: newDates
            }
        });
    }

    markEveryWeekend = (e) => {
        e.preventDefault();
        const newDates = [];
        const startDate = moment();
        const stopDate = moment().add(1, "year");
        const current = startDate.clone();
        while(current.day(7).isSameOrBefore(moment(stopDate))) {
            newDates.push(new Date(current.clone().subtract(1, "d")));
            newDates.push(new Date(current.clone()));
        }
        this.setState({ 
            data: {
                ...this.state.data,
                dates: newDates
            }
        });
    }

    clearAllDates = (e) => {
        e.preventDefault();
        this.setState({
            data: {
                ...this.state.data,
                dates: [],
            },
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        // Conditional Post - Put 
        let url = "";
        url = this.state.isEdit ? `${addTrip}/${this.state.id}` : addTrip;

        const payload = this.state.data;
        payload.capacity = +payload.capacity;
        payload.duration = +payload.duration;
        payload.price = +payload.price;
        payload.discount = +payload.discount;
        payload.region = payload.region.value;
        payload.startingLocation = payload.startingLocation.value;
        payload.type = payload.type.value;
        payload.vendor = payload.vendor.value;
        payload.locations = payload.locations.map(item => item.value);
        payload.recommendedGear = payload.recommendedGear.map(item => item.value);
        payload.whatsNotIncluded = payload.whatsNotIncluded.map(item => item.value);
        payload.facilities = payload.facilities.map(item => item.value);
        payload.theme = payload.theme.map(item => item.value);

        if(this.state.isEdit) {
            payload.status = payload.status.value;
            axios
                .put(url, payload)
                .then(response => {
                    swal("Success", response.data.success, "success");
                })
                .catch(error => {
                    swal(error.response.statusText, error.response.data.error, "error");
                });
        }
        else {
            axios
                .post(url, payload)
                .then(response => {
                    swal("Success", response.data.success, "success");
                    setTimeout(() => {
                        window.open(ClientRoute(`trip/${response.data.id}`), '_blank');
                    }, 3000);
                })
                .catch(error => {
                    swal(error.response.statusText, error.response.data.error, "error");

                });
        }
    }

    createDuplicate = (e) => {
        e.preventDefault();
        axios
            .post(`${addTrip}/duplicate`, {
                id: this.state.id,
            })
            .then(response => {
                if(response.data.success) {
                    swal("Success", response.data.success, "success");
                }
            })
            .catch(error => {
                swal(error.response.statusText, error.response.data.error, "error")
            });
    }

    htmlDecode(content) {
        const e = document.createElement('div');
        e.innerHTML = content;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }      

    render() {
        const { view, slug } = this.state;
        const { title, capacity, duration, region, type, isFeatured,
            startingLocation, price, discount, vendor, locations, theme,
            facilities, whatsNotIncluded, recommendedGear, desc, 
            cancellationPolicy, itinerary } = this.state.data;
        return (
            <form>
                {view.general && (
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">General</li>
                            </ol>
                        </nav>
                        {this.state.isEdit && (
                            <>
                                <div className="form-group">
                                    <label>Trip Status</label>
                                    <RSelect 
                                        options={tripStatus}
                                        value={this.state.data.status}
                                        onChange={(e) => this.handleStatus(e, "status")}
                                    />
                                </div>
                                <div className="text-right">
                                        <button 
                                            className="btn btn-primary m-2" 
                                            onClick={()=> window.open(`https://tripkarao.pk/trips/${slug}`, "_blank")}
                                        >View Trip</button>
                                    <button 
                                        className="btn btn-primary m-2"
                                        onClick={this.createDuplicate}>Duplicate this trip</button>
                                </div>
                            </>
                        )}
                        <div className="form-group">
                            <label htmlFor="title">Title</label>
                            <input 
                                id="title" 
                                name="title" 
                                className="form-control" 
                                value={title}
                                onChange={this.handleChange}
                                // disabled={this.state.isEdit}
                            />
                        </div>
                        <div className="form-row">
                            <div className="form-group col-sm-12 col-md-6">
                                <label htmlFor="capacity">Capacity</label>
                                <input 
                                    id="capacity" 
                                    name="capacity" 
                                    className="form-control" 
                                    type="number"
                                    value={capacity}
                                    onChange={this.handleChange}
                                    min={0}
                                />
                            </div>
                            <div className="form-group col-sm-12 col-md-6">
                                <label htmlFor="duration">Duration</label>
                                <input 
                                    id="duration" 
                                    name="duration" 
                                    className="form-control" 
                                    type="number"
                                    value={duration}
                                    onChange={this.handleChange}
                                    min={0}
                                />
                            </div>
                            <div className="form-group col-sm-12 col-md-6">
                                <label htmlFor="region">Region</label>
                                <RSelect 
                                    name="region"
                                    options={tripRegions}
                                    value={region}
                                    onChange={(e) => this.handleSelect(e, "region")}
                                />
                            </div>
                            <div className="form-group col-sm-12 col-md-6">
                                <label htmlFor="type">Type</label>
                                <RSelect 
                                    name="type"
                                    options={tripTypes}
                                    value={type}
                                    onChange={(e) => this.handleSelect(e, "type")}
                                />
                            </div>
                            <div className="form-group  col-sm-12 col-md-6">
                                <label htmlFor="startingLocation">Starting Location</label>
                                <RSelect 
                                    name="startingLocation"
                                    options={tripStartingLocations}
                                    value={startingLocation}
                                    onChange={(e) => this.handleSelect(e, "startingLocation")}
                                />
                            </div>
                            <div className="form-group col-sm-12 col-md-6">
                                <label htmlFor="price">Price</label>
                                <input 
                                    id="price" 
                                    name="price" 
                                    className="form-control" 
                                    type="number"
                                    value={price}
                                    onChange={this.handleChange}
                                    min={0}
                                />
                            </div>
                            <div className="form-group col-sm-12 col-md-6">
                                <label htmlFor="vendor">Vendor</label>
                                <RSelect 
                                    name="vendor"
                                    options={this.state.vendors}
                                    value={vendor}
                                    onChange={(e) => this.handleSelect(e, "vendor")}
                                />
                            </div>
                            <div className="form-group col-sm-12 col-md-6">
                                <label htmlFor="discount">Discount</label>
                                <div className="input-group">
                                    <input 
                                        id="discount" 
                                        name="discount" 
                                        className="form-control" 
                                        type="number"
                                        value={discount}
                                        onChange={this.handleChange}
                                        min={0}
                                        max={100}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text" id="basic-addon2">%</span>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="desc">Description</label>
                            <textarea 
                                name="desc"
                                className="form-control" 
                                onChange={this.handleChange}
                                rows="5"
                                value={desc}
                            />
                        </div>
                        <div className="form-check">
                            <input 
                                name="isFeatured"
                                className="form-check-input" 
                                type="checkbox" 
                                checked={isFeatured} 
                                onChange={this.handleChange}
                            />
                            <label className="form-check-label" htmlFor="isFeatured">Mark Featured</label>
                        </div>
                        <div className="form-group mt-5 text-right">
                        <button 
                            className="btn w-25 btn-outline-primary"
                            onClick={() => this.handleView(2)}>Next</button>
                    </div>
                    </div>
                )}
                {view.itinerary && (
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Itinerary</li>
                            </ol>
                        </nav>
                        {this.state.isEdit && <div dangerouslySetInnerHTML={{ __html: this.htmlDecode(itinerary) }} />}
                        <div className="form-group">
                            <CKEditor 
                                data={itinerary}
                                onChange={e => this.handleEditor(e.editor.getData(), "itinerary")}
                            />
                        </div>
                        <div className="row mt-5">
                            <div className="col-6">
                                <button 
                                    className="btn w-25 btn-outline-secondary"
                                    onClick={() => this.handleView(1)}>Previous</button>
                            </div>
                            <div className="col-6 text-right">
                                <button 
                                    className="btn w-25 btn-outline-primary"
                                    onClick={() => this.handleView(3)}>Next</button>
                            </div>
                        </div>
                    </div>
                )}
                {view.addons && (
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Addons</li>
                            </ol>
                        </nav>
                        <Addons 
                            pushData={this.getAddons} 
                            data={this.state.data && this.state.data.addons} 
                        />
                        <div className="row mt-5">
                            <div className="col-6">
                                <button 
                                    className="btn w-25 btn-outline-secondary"
                                    onClick={() => this.handleView(2)}>Previous</button>
                            </div>
                            <div className="col-6 text-right">
                                <button 
                                    className="btn w-25 btn-outline-primary"
                                    onClick={() => this.handleView(4)}>Next</button>
                            </div>
                        </div>
                    </div>
                )}
                {view.availableDates && (
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Available Dates</li>
                            </ol>
                        </nav>
                        <div className="row">
                            <div className="col-sm-12 col-lg-6">
                                <div className="form-group">
                                    <DayPicker 
                                        selectedDays={this.state.data.dates}
                                        onDayClick={this.handleDayClick}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <button 
                                    onClick={this.markEveryday}
                                    className="btn btn-info mr-3">Mark Every Day</button>
                                <button 
                                    onClick={this.markEveryWeekend}
                                    className="btn btn-info mr-3">Mark Every Weekend</button>
                                <button 
                                    onClick={this.clearAllDates}
                                    className="btn btn-info">Clear All Dates</button>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-6">
                                <button 
                                    className="btn w-25 btn-outline-secondary"
                                    onClick={() => this.handleView(3)}>Previous</button>
                            </div>
                            <div className="col-6 text-right">
                                <button 
                                    className="btn w-25 btn-outline-primary"
                                    onClick={() => this.handleView(5)}>Next</button>
                            </div>
                        </div>
                    </div>
                )}
                {view.services && (
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">Misc</li>
                            </ol>
                        </nav>
                        <div className="form-group">
                            <label htmlFor="locations">Locations</label>
                            <RCreateableSelect
                                isMulti
                                closeMenuOnSelect={false}
                                options={tripLocations}
                                value={locations}
                                onChange={(e) => this.handleSelect(e, "locations")}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="theme">Theme</label>
                            <RCreateableSelect 
                                isMulti
                                closeMenuOnSelect={false}
                                options={tripThemes}
                                value={theme}
                                onChange={(e) => this.handleSelect(e, "theme")}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="facilities">Facilities</label>
                            <RCreateableSelect 
                                isMulti
                                closeMenuOnSelect={false}
                                options={tripFacilities}
                                value={facilities}
                                onChange={(e) => this.handleSelect(e, "facilities")}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="whatsNotIncluded">Whats Not Included</label>
                            <RCreateableSelect 
                                isMulti
                                closeMenuOnSelect={false}
                                options={tripWhatsNotIncluded}
                                value={whatsNotIncluded}
                                onChange={(e) => this.handleSelect(e, "whatsNotIncluded")}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="recommendedGear">Recommended Gear</label>
                            <RCreateableSelect 
                                isMulti
                                closeMenuOnSelect={false}
                                options={tripRecommendedGear}
                                value={recommendedGear}
                                onChange={(e) => this.handleSelect(e, "recommendedGear")}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="cancellationPolicy">Cancellation Policy</label>
                            <textarea 
                                name="cancellationPolicy"
                                className="form-control" 
                                onChange={this.handleChange}
                                rows="5"
                                value={cancellationPolicy}
                            />
                        </div>
                        <div className="row mt-5">
                            <div className="col-6">
                                <button 
                                    className="btn w-25 btn-outline-secondary"
                                    onClick={() => this.handleView(4)}>Previous</button>
                            </div>
                            <div className="col-6 text-right">
                                <button 
                                    className="btn w-25 btn-outline-success"
                                    onClick={this.handleSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                )}
            </form>
        )
    }
}

export default AddTrip;