export const status = [
    { label: "Hold", value: "hold" },
    { label: "Paid", value: "paid" },
    { label: "Drop", value: "dropped"},
];

export const type = [
    { label: "New", value: "new" },
    { label: "Expired", value: "expired" },
    { label: "Cancelled", value: "cancelled"},
];