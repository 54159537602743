import React, { Component } from "react";
import axios from "axios";
import swal from 'sweetalert';
import qs from "qs";
import { users } from "../../../config/routes/server";
import Spinner from "../../common/Spinner";
import moment from "moment";

const columns = [
    {
        Header: "Name",
    },
    {
        Header: "Type",
    },
    {
        Header: "Phone",
    },
    {
        Header: "Gender",
    },
    {
        Header: "City",
    },
    {
        Header: "Country",
    },
    {
        Header: "Points",
    },
    {
        Header: "Created At",
    }
];

class GetUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            meta: {},
            page: 1,
            isLoading: true,
            email: "",
        }
    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers = (query) => {
        let url = users;
        let pagination = {size: null, page: this.state.page}
        url += "?" + qs.stringify(pagination);

        if(query) {
            url += "&" + qs.stringify(query);
        }
        axios
            .get(url)
            .then(response => {
                this.setState({
                    data: response.data.users,
                    meta: response.data.meta,
                    isLoading: false,
                });
            })
            .catch(error => {
                swal(error.response.statusText, error.response.data.error, "error")
            });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handlePagination = (page) => {
        this.setState({ page: page }, () => this.getUsers());
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.getUsers({
            email: this.state.email,
        });
    }

    render() {
        const { data, meta, isLoading } = this.state;
        const pagination = meta.total > 10 ? (
            <div className="btn-toolbar" role="toolbar" aria-label="pagination">
                <div className="btn-group mr-2" role="group" aria-label="First group">
                    <button 
                        type="button" 
                        className="btn btn-info"
                        onClick={() => this.handlePagination(1)}>1</button>
                    <button 
                        type="button" 
                        className="btn btn-info"
                        onClick={() => this.handlePagination(2)}>2</button>
                    <button 
                        type="button" 
                        className="btn btn-info"
                        onClick={() => this.handlePagination(3)}>3</button>
                    <button 
                        type="button" 
                        className="btn btn-info"
                        onClick={() => this.handlePagination(4)}>4</button>
                </div>
            </div>
        ) : null;
        return (
            <>
                <div className="mb-3">
                    <div className="container">
                        <div className="form-group">
                            <label>Search by Email</label>
                            <input 
                                name="email"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.email} 
                            />
                        </div>
                        <div className="text-end">
                            <button 
                                className="btn btn-outline-primary" 
                                onClick={this.handleSearch}>Search</button>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                {columns.map((column, index) => (
                                    <th scope="col" key={index}>{column.Header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading && <Spinner />}
                            {!isLoading && data && data.map(item => (
                                <tr key={item._id}>
                                    <th scope="row">
                                        {/* <Link to={ClientRoute(`vendors/${item._id}`)}> */}
                                            {item?.name}
                                        {/* </Link> */}
                                    </th>
                                    <td>{item?.type}</td>
                                    <td>{item?.phone}</td>
                                    <td>{item?.gender}</td>
                                    <td>{item?.city}</td>
                                    <td>{item?.country}</td>
                                    <td>{item?.points}</td>
                                    <td>{moment(item.createdAt).format('DD-MM-YYYY')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {pagination}
                </div>
            </>
        )
    }
}

export default GetUsers;