import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../../config/redux/actions/auth";
import ClientRoute from "../../../config/routes/helper";
import swal from "sweetalert";

class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            isAuthenticating: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push(ClientRoute(""));
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            this.props.history.push(ClientRoute(""));
        }

        if (nextProps.errors) {
            if(nextProps.errors && nextProps.errors.error) {
                this.setState({
                    isAuthenticating: false,
                }, () => {
                    swal("Error", nextProps.errors.error, "error");
                });
            }
            if(nextProps.errors && nextProps.errors.message) {
                this.setState({
                    isAuthenticating: false,
                }, () => {
                    swal("Error", nextProps.errors.message, "error");
                });
            }
        }
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();

        const userData = {
            email: this.state.email,
            password: this.state.password,
        };

        this.setState({ isAuthenticating: true });
        this.props.loginUser(userData);
    }

    render() {
        const { email, password, isAuthenticating } = this.state;

        return (
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-8" style={{ height: "100vh" }}>
                        <form className="form-signin">
                            <div className="text-center mb-4">
                                <h1 className="h3 mb-3 font-weight-normal">TripKarao - Admin</h1>
                            </div>
                            <div className="form-label-group">
                                <label htmlFor="inputEmail">Email address</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={email}
                                    id="inputEmail"
                                    className="form-control"
                                    placeholder="Email address"
                                    required
                                    autoFocus
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-label-group">
                                <label htmlFor="inputPassword">Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    value={password}
                                    id="inputPassword"
                                    className="form-control"
                                    placeholder="Password"
                                    required
                                    onChange={this.handleChange}
                                />
                            </div>
                            <button
                                className="btn btn-lg btn-primary btn-block mt-3"
                                onClick={this.handleSubmit} type="submit">
                                {isAuthenticating ? "Authenticating..." : "Sign in"}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

SignIn.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(withRouter(SignIn));