import React from "react";

function NotFound() {
    return(
        <h1 className="text-center text-danger" style={{marginTop: "15%"}}>
            <span style={{fontSize: 80}}>Error 404!</span><br />
            Not Found
        </h1>
    );
}

export default NotFound;