import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";

import Spinner from "../../common/Spinner";

import { bookings } from "../../../config/routes/server";
import ClientRoute from "../../../config/routes/helper";

const columns = [
    {
        Header: "S.No#",
        id: '0'
    },
    {
        Header: "Trip",
        id: '1'
    },
    {
        Header: "Booking Date",
        id: "2"
    },
    {
        Header: "Booking Amount",
        id: "3"
    },
    {
        Header: "Type",
        id: "4"
    },
    {
        Header: "Status",
        id: "5"
    },
    {
        Header: "Payment Date",
        id: "6"
    },
    {
        Header: "Payment Method",
        id: "7"
    },
    {
        Header: "Payment Charges",
        id: "8"
    },
    {
        Header: "Payment Amount",
        id: "9"
    },
    {
        Header: "Created By",
        id: "11"
    },
];

class GetBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            meta: {},
            isLoading: true,
            error: null
        }
    }

    componentDidMount() {
        this.getBookings({size: null, page: null});
    }

    getBookings = (query) => {
        axios
            .get(`${bookings}?size=${query.size}&page=${query.page}`)
            .then(response => {
                this.setState({
                    data: response.data.bookings,
                    meta: response.data.meta,
                    isLoading: false,
                });
            })
            .catch(error => {
                this.setState({
                    error: error.response,
                    isLoading: false,
                })
            })
    }

    render() {
        const { data, meta, isLoading } = this.state;
        const pagination = meta.total > 1 ? (
            <div className="btn-toolbar" role="toolbar" aria-label="pagination">
                <div className="btn-group mr-2" role="group" aria-label="First group">
                    <button type="button" className="btn btn-info">1</button>
                    <button type="button" className="btn btn-info">2</button>
                    <button type="button" className="btn btn-info">3</button>
                    <button type="button" className="btn btn-info">4</button>
                </div>
            </div>
        ) : null;
        return (
            <>
                <h1 className="text-center">Bookings</h1>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                {columns.map(column => (
                                    <th scope="col" key={column.id}>{column.Header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading && <Spinner />}
                            {!isLoading && data && data.map((item, index) => (
                                <tr key={item._id}>
                                    <th scope="row" className="text-center">
                                        {index + 1}
                                    </th>
                                    <td>
                                        <Link to={ClientRoute(`booking/${item._id}`)}>{item.trip && item.trip.title}</Link>
                                    </td>
                                    <td>{item.bookingDetails && moment(item.bookingDetails.date).format("DD-MM-YY")}</td>
                                    <td>{item.bookingDetails && item.bookingDetails.amount}</td>
                                    <td>{item.bookingType}</td>
                                    <td>{item.bookingStatus}</td>
                                    <td>{item.paymentDetails && moment(item.paymentDetails.date).format("DD-MM-YY")}</td>
                                    <td>{item.paymentDetails && item.paymentDetails.method}</td>
                                    <td>{item.paymentDetails && item.paymentDetails.charges}</td>
                                    <td>{item.paymentDetails && item.paymentDetails.amount}</td>
                                    <td>{item.createdBy && item.createdBy.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {data.length === 0 && "No Bookings found"}
                    {pagination}
                </div>
            </>
        )
    }
}

export default GetBooking;