import React, { Component } from "react";
import axios from "axios";
import swal from 'sweetalert';
import { Link } from "react-router-dom";
import qs from "qs";
import { vendors } from "../../../config/routes/server";
import ClientRoute from "../../../config/routes/helper";
import Spinner from "../../common/Spinner";

const columns = [
    {
        Header: "Company Name",
    },
    {
        Header: "Vendor Name",
    },
    {
        Header: "Email",
    },
    {
        Header: "Primary Phone",
    },
    {
        Header: "NTN",
    },
    {
        Header: "Facebook",
    },
    {
        Header: "Bank Account Title",
    },
    {
        Header: "Bank Account No#",
    },
    {
        Header: "Bank Account Branch",
    },
    {
        Header: "Location",
    }
];

class GetVendors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            meta: {},
            page: 1,
            isLoading: true,
        }
    }

    componentDidMount() {
        this.getVendors();
    }

    getVendors = (query) => {
        let url = vendors;
        let pagination = {size: null, page: this.state.page}
        url += "?" + qs.stringify(pagination);

        if(query) {
            url += "&" + qs.stringify(query);
        }
        axios
            .get(url)
            .then(response => {
                this.setState({
                    data: response.data.vendors,
                    meta: response.data.meta,
                    isLoading: false,
                });
            })
            .catch(error => {
                swal(error.response.statusText, error.response.data.error, "error")
            });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handlePagination = (page) => {
        this.setState({ page: page }, () => this.getVendors());
    }

    render() {
        const { data, meta, isLoading } = this.state;
        const pagination = meta.total > 10 ? (
            <div className="btn-toolbar" role="toolbar" aria-label="pagination">
                <div className="btn-group mr-2" role="group" aria-label="First group">
                    <button 
                        type="button" 
                        className="btn btn-info"
                        onClick={() => this.handlePagination(1)}>1</button>
                    <button 
                        type="button" 
                        className="btn btn-info"
                        onClick={() => this.handlePagination(2)}>2</button>
                    <button 
                        type="button" 
                        className="btn btn-info"
                        onClick={() => this.handlePagination(3)}>3</button>
                    <button 
                        type="button" 
                        className="btn btn-info"
                        onClick={() => this.handlePagination(4)}>4</button>
                </div>
            </div>
        ) : null;
        return (
            <>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                {columns.map((column, index) => (
                                    <th scope="col" key={index}>{column.Header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading && <Spinner />}
                            {!isLoading && data && data.map(item => (
                                <tr key={item._id}>
                                    <th scope="row">
                                        <Link to={ClientRoute(`vendors/${item._id}`)}>{item.companyName}</Link>
                                    </th>
                                    <td>{item.name}</td>
                                    <td>{item.email}</td>
                                    <td>{item.primaryPhone}</td>
                                    <td>{item.ntn}</td>
                                    <td>{item.facebook}</td>
                                    <td>{item.bankAccountTitle}</td>
                                    <td>{item.bankAccountNo}</td>
                                    <td>{item.bankAccountBranch}</td>
                                    <td>{item.city + " " + item.country}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {pagination}
                </div>
            </>
        )
    }
}

export default GetVendors;