const host = process.env.NODE_ENV === "production" 
    ? "https://api.tripkarao.pk/api" : "http://localhost:3001/api";

// const host = "https://tripkarao.pk/api";

export const login = `${host}/login`;
export const trips = `${host}/trips/admin`;
export const addTrip = `${host}/trip`;
export const bookings = `${host}/bookings`;
export const vendors = `${host}/vendors`;
export const home = `${host}/home`;
export const queries = `${host}/queries`;
export const locations = `${host}/locations`;
export const regions = `${host}/regions`;
export const users = `${host}/users`;
