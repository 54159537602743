import React from "react";
import { Switch, Route } from "react-router-dom";

import PrivateRoute from "../../utils/PrivateRoute";
import ClientRoute from "../routes/helper";

import Home from "../../components/views/Home";
import SignIn from "../../components/views/Auth";
import GetTrips from "../../components/views/Trips";
import AddTrip from "../../components/views/Trips/Add";
import UploadTripImages from "../../components/views/Trips/UploadImages";
import GetBookings from "../../components/views/Bookings";
import GetSingleBooking from "../../components/views/Bookings/Single";
import GetVendors from "../../components/views/Vendors";
import AddVendor from "../../components/views/Vendors/Add";
import Customized from "../../components/views/Customized";
import GetRegions from "../../components/views/Regions";
import AddRegion from "../../components/views/Regions/Add";
import GetLocations from "../../components/views/Locations";
import AddLocation from "../../components/views/Locations/Add";
import UploadLocationImages from "../../components/views/Locations/UploadImages";
import GetUsers from "../../components/views/Users";
import NotFound from "../../components/views/NotFound";

function Routes() {
    return (
        <Switch>
            <Route exact path={ClientRoute("sign-in")} component={SignIn} />
            <PrivateRoute exact path={ClientRoute("")} component={Home} />
            <PrivateRoute exact path={ClientRoute("trips")} component={GetTrips} />
            <PrivateRoute exact path={ClientRoute("add-trip")} component={AddTrip} />
            <PrivateRoute exact path={ClientRoute("trip/:id")} component={AddTrip} />
            <PrivateRoute exact path={ClientRoute("trip/:id/images")} component={UploadTripImages} />
            <PrivateRoute exact path={ClientRoute("bookings")} component={GetBookings} />
            <PrivateRoute exact path={ClientRoute("booking/:id")} component={GetSingleBooking} />
            <PrivateRoute exact path={ClientRoute("vendors")} component={GetVendors} />
            <PrivateRoute exact path={ClientRoute("add-vendor")} component={AddVendor} />
            <PrivateRoute exact path={ClientRoute("vendors/:id")} component={AddVendor} />
            <PrivateRoute exact path={ClientRoute("customized")} component={Customized} />
            <PrivateRoute exact path={ClientRoute("regions")} component={GetRegions} />
            <PrivateRoute exact path={ClientRoute("add-region")} component={AddRegion} />
            <PrivateRoute exact path={ClientRoute("regions/:id")} component={AddRegion} />
            <PrivateRoute exact path={ClientRoute("locations")} component={GetLocations} />
            <PrivateRoute exact path={ClientRoute("add-location")} component={AddLocation} />
            <PrivateRoute exact path={ClientRoute("locations/:id")} component={AddLocation} />
            <PrivateRoute exact path={ClientRoute("locations/:id/images")} component={UploadLocationImages} />
            <PrivateRoute exact path={ClientRoute("users/")} component={GetUsers} />
            <Route path="*">
                <NotFound />
            </Route>
        </Switch>
    )
}

export default Routes;