import React, { Component } from "react";
import axios from "axios";
import swal from 'sweetalert';

import { regions } from "../../../config/routes/server";

class AddRegion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                title: "",
                isFeatured: false,
                isLocal: true,
                desc: "",
                isActive: true,
            },
            isEdit: false,
            id: "",
        };
    }

    componentDidMount() {
        if(this.props.match.params.id) {
            this.setState({
                id: this.props.match.params.id
            }, () => {
                axios
                    .get(`${regions}/admin/${this.state.id}`)
                    .then(response => {
                        this.setState({
                            data: response.data,
                            isEdit: true
                        });
                    })
                    .catch(error => {
                        swal(error.response.statusText, error.response.data.error, "error");
                    });
            });
        }
    }

    handleChange = (e) => {
        if(e.target.type === "checkbox") {
            this.setState({
                data: {
                    ...this.state.data,
                    [e.target.name]: e.target.checked
                }
            });
        }
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSelect = (e, name) => {
        this.setState({
            data: {
                ...this.state.data,
                [name]: e
            }
        });
    }
    
    handleSubmit = (e) => {
        e.preventDefault();

        // Conditional Post - Put 
        let url = "";
        if(this.state.isEdit) {
            url = `${regions}/${this.state.id}`;
        }
        else {
            url = regions;
        }

        let payload = this.state.data;
        if(this.state.isEdit) {
            axios
                .put(url, payload)
                .then(response => {
                    swal("Success", response.data.success, "success");
                })
                .catch(error => {
                    swal(error.response.statusText, error.response.data.error, "error")
                });
        }
        else {
            axios
                .post(url, payload)
                .then(response => {
                    swal("Success", response.data.success, "success");
                })
                .catch(error => {
                    swal(error.response.statusText, error.response.data.error, "error")
                });
        }
    }

    render() {
        const { title, isFeatured, isLocal, desc } = this.state.data;
        return (
            <form>
                <h3 className="lead text-center m-5">{this.state.isEdit ? "Edit" : "Add"} Region</h3>
                <div className="form-group">
                    <label>Title</label>
                    <input 
                        name="title" 
                        className="form-control" 
                        value={title}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="form-group">
                    <label>Description</label>
                    <textarea 
                        className="form-control"
                        name="desc"
                        value={desc}
                        onChange={this.handleChange}></textarea>
                </div>
                <div className="form-check">
                    <input 
                        name="isFeatured"
                        id="isFeatured"
                        className="form-check-input" 
                        type="checkbox" 
                        checked={isFeatured} 
                        value={isFeatured} 
                        onChange={this.handleChange}
                    />
                    <label className="form-check-label" htmlFor="isFeatured">Mark Featured</label>
                </div>
                <div className="form-check">
                    <input 
                        name="isLocal"
                        id="isLocal"
                        className="form-check-input" 
                        type="checkbox" 
                        checked={isLocal} 
                        value={isLocal} 
                        onChange={e => this.setState({ data: { ...this.state.data, isLocal: e.target.checked } })}
                    />
                    <label className="form-check-label" htmlFor="isLocal">Is the location local?</label>
                </div>
                <div className="text-right">
                    <button 
                        className="btn w-25 btn-outline-success"
                        onClick={this.handleSubmit}>Submit</button>
                </div>
            </form>
        )
    }
}

export default AddRegion;