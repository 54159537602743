import React, { Component } from "react";
import axios from "axios";
import swal from 'sweetalert';
import moment from "moment";
import { queries } from "../../../config/routes/server";
import Spinner from "../../common/Spinner";

const columns = [
    {
        Header: "Full Name",
    },
    {
        Header: "email",
    },
    {
        Header: "Mobile",
    },
    {
        Header: "Adults & Children",
    },
    {
        Header: "Departing Date",
    },
    {
        Header: "Returning Date",
    },
    {
        Header: "Duration",
    },
    {
        Header: "Departure City",
    },
    {
        Header: "Budget",
    },
    {
        Header: "Locations",
    },
    {
        Header: "Themes",
    },
    {
        Header: "Details",
    },
    {
        Header: "Received",
    }
];

class GetVendors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            meta: {},
            page: 1,
            isLoading: true,
        }
    }

    componentDidMount() {
        this.getQueries();
    }

    getQueries = () => {
        let url = queries;
        axios
            .get(url)
            .then(response => {
                this.setState({
                    data: response.data,
                    isLoading: false,
                });
            })
            .catch(error => {
                swal(error.response.statusText, error.response.data.error, "error")
            });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handlePagination = (page) => {
        this.setState({ page: page }, () => this.getQueries());
    }

    render() {
        const { data, isLoading } = this.state;
        return (
            <>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                {columns.map((column, index) => (
                                    <th scope="col" key={index}>{column.Header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading && <Spinner />}
                            {!isLoading && data && data.map(item => (
                                <tr key={item._id}>
                                    <th scope="row">
                                        {item.fullName}
                                    </th>
                                    <td>{item.email}</td>
                                    <td>{item.mobile}</td>
                                    <td>{item.adults} - {item.children}</td>
                                    <td>{moment(item.departingDate).format("DD-MM-YYYY")}</td>
                                    <td>{item.returningDate !== null 
                                        ? moment(item.returningDate).format("DD-MM-YYYY") 
                                        : "Not Available" }</td>
                                    <td>{item.duration}</td>
                                    <td>{item.departureCity}</td>
                                    <td>{item.budget ? item.budget : "Not Available"}</td>
                                    <td>{item.locations ? item.locations.join(", ") : "None"}</td>
                                    <td>{item.themes ? item.themes.join(", ") : "None"}</td>
                                    <td>{item.details}</td>
                                    <td>{moment(item.createdAt).format("DD-MM-YYYY")}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}

export default GetVendors;