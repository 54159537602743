import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import swal from 'sweetalert';
import moment from "moment";
import { bookings } from "../../../config/routes/server";
import {status, type} from "../../../config/variables/bookings";
import formatNumber from "../../helpers/formatNumbers";

class GetBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            data: {
                bookingDetails: {},
                bookingExpiry: "",
                bookingStatus: "",
                bookingType: "",
                createdAt: "",
                createdBy: {},
                index: "",
                note: "",
                paymentDetails: {},
                persons: "",
                trip: {},
                updatedAt: "",
                _id: "",
            }
        };
    }

    componentDidMount() {
        if(this.props.match.params.id) {
            this.setState({
                id: this.props.match.params.id
            }, () => {
                axios
                    .get(`${bookings}/${this.state.id}`)
                    .then(response => {
                        this.setState({
                            data: response.data,
                        });
                    })
                    .catch(error => {
                        swal(error.response.statusText, error.response.data.error, "error");
                    })
            });
        }
    }

    handleChange = (e) => {
        if(e.target.type === "checkbox") {
            this.setState({
                data: {
                    ...this.state.data,
                    [e.target.name]: e.target.checked
                }
            });
        }
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: e.target.value
            }
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let url = `${bookings}/${this.state.id}`;
        let payload = this.state.data;
        payload.updatedAt = Date.now();
        delete payload._id;
        axios
            .put(url, payload)
            .then(response => {
                swal("Success", response.data.success, "success");
            })
            .catch(error => {
                swal(error.response.statusText, error.response.data.error, "error")
            });
    }

    render() {
        const {data} = this.state;
        return (
            <form>
                <h3 className="lead text-center m-5">Booking</h3>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label>Type</label>
                                    <select 
                                        name="bookingType"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.data.bookingType}>
                                            <option value="">Select Vendor</option>
                                            {type.map((item, index) => (
                                                <option key={index} value={item.value}>{item.label}</option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label>Status</label>
                                    <select 
                                        name="bookingStatus"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.data.bookingStatus}>
                                        {status.map((item, index) => (
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 text-right">
                                <button 
                                    className="btn btn-outline-primary" 
                                    onClick={this.handleSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Booking No# {data.index}</h5>
                            <h6 className="card-subtitle mb-2 text-muted">Date: {data.bookingDetails &&
                            moment(data.bookingDetails.date).format("DD MMMM, YYYY")}</h6>
                            <div className="row mt-3">
                                <div className="col-sm-12 col-md-4">
                                    <p><strong>Customer Info</strong></p>
                                    <ul className="list-unstyled">
                                        <li>Name: {data.createdBy.name}</li>
                                        <li>Email: {data.createdBy.email}</li>
                                        <li>Phone: {data.createdBy.phone}</li>
                                        <li>City: {data.createdBy.city}</li>
                                    </ul>
                                </div>
                                <div className="col-sm-12 col-md-4">
                                    <p><strong>Booking & Payment Info</strong></p>
                                    <ul className="list-unstyled">
                                        <li>Booking Amount: <i>PKR {formatNumber(data.bookingDetails.amount)}</i></li>
                                        <li>Payment Amount: <i>PKR {formatNumber(data.paymentDetails.amount)}</i></li>
                                        <li>Discount (1 person): <i>{data.bookingDetails.discount}</i></li>
                                        <li>Payment Method: <i>{data.paymentDetails.method}</i></li>
                                        <li>Payment Charges: <i>PKR {data.paymentDetails.charges}</i></li>
                                        <li>Person(s): <i>{data.persons}</i></li>
                                    </ul>
                                </div>
                                <div className="col-sm-12 col-md-4">
                                    <p><strong>Trip Info</strong></p>
                                    <ul className="list-unstyled">
                                        <li>Title: <i>{data.trip.title}</i></li>
                                        <li>price: <i>PKR {formatNumber(data.trip.price)}</i></li>
                                        <li>Vendor: {data.trip.vendor && data.trip.vendor.name}</li>
                                        <li><b>{data.trip.vendor && data.trip.vendor.companyName}</b></li>
                                        {data.note && data.note.length > 0 && (
                                            <>
                                                <br />
                                                <li>Note: {data.note}</li>
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}

export default withRouter(GetBooking);