import React, { Component, Fragment } from "react";
import axios from "axios";
import swal from 'sweetalert';
import { Link } from "react-router-dom";
import _ from "lodash";
import qs from "qs";
import { trips, vendors } from "../../../config/routes/server";
import { tripStatus, } from "../../../config/variables/trip";
import ClientRoute from "../../../config/routes/helper";
import Spinner from "../../common/Spinner";

const columns = [
    {
        Header: "#",
    },
    {
        Header: "Title",
    },
    {
        Header: "Vendor",
    },
    {
        Header: "Type",
    },
    {
        Header: "Duration",
    },
    {
        Header: "Status",
    },
    {
        Header: "Featured",
    },
    {
        Header: "Price",
    },
    {
        Header: "Created By",
    },
    {
        Header: "Actions",
    },
    {
        Header: "Other",
    },
];

class GetTrips extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            meta: {},
            title: "",
            status: "",
            vendor: "",
            page: 1,
            size: 10,
            vendors: [],
            isLoading: true,
        }
    }

    componentDidMount() {
        this.getTrips();
        this.getVendors();
    }

    getTrips = (query) => {
        let url = trips;
        const pagination = {size: this.state.size, page: this.state.page}
        url += `?${qs.stringify(pagination)}`;

        if(query) {
            url += `&${qs.stringify(query)}`;
        }
        axios
            .get(url)
            .then(response => {
                this.setState({
                    data: response.data.trips,
                    meta: response.data.meta,
                    isLoading: false,
                });
            })
            .catch(error => {
                swal(error.response.statusText, error.response.data.error, "error");
            });
    }

    getVendors = (query) => {
        let url = vendors;
        const pagination = {size: 0, page: 1}
        url += `?${qs.stringify(pagination)}`;

        if(query) {
            url += `&${qs.stringify(query)}`;
        }
        axios
            .get(url)
            .then(response => {
                let array = [];
                response.data.vendors.forEach(item => {
                    array.push({
                        label: item.companyName,
                        value: item._id
                    });
                })
                this.setState({
                    vendors: array,
                });
            })
            .catch(error => {
                swal(error.response.statusText, error.response.data.error, "error")
            });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.getTrips({
            status: this.state.status, 
            keyword: this.state.title,
            vendor: this.state.vendor
        });
    }

    handlePagination = (page) => {
        this.setState({ page }, () => this.getTrips());
    }

    render() {
        const { 
            data, 
            isLoading,
        } = this.state;
        return (
            <>
                <div className="mb-3">
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label>Search by Title</label>
                                <input 
                                    name="title"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.title} 
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label>Vendor</label>
                                <select 
                                    name="vendor"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.vendor}>
                                            <option value="">Select Vendor</option>
                                    {this.state.vendors && this.state.vendors.map((item, index) => (
                                        <Fragment key={index}>
                                            <option value={item.value}>{item.label}</option>
                                        </Fragment>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label>Status</label>
                                <select 
                                    name="status"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.status}>
                                    {tripStatus.map((item, index) => (
                                        <option key={index} value={item.value}>{item.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-12 text-right">
                            <button 
                                className="btn btn-outline-primary" 
                                onClick={this.handleSearch}>Search</button>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                {columns.map((column, index) => (
                                    <th scope="col" key={index}>{column.Header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading && <Spinner />}
                            {!isLoading && data && data.map((item, index) => (
                                <tr key={item._id}>
                                    <th scope="row">
                                        {index + 1}
                                    </th>
                                    <td>
                                        <Link to={ClientRoute(`trip/${item._id}`)}>{item.title}</Link>
                                    </td>
                                    <td>{item.vendor.companyName}</td>
                                    <td>{item.type}</td>
                                    <td>{item.duration}</td>
                                    <td>{_.startCase(item.status)}</td>
                                    <td>{item.isFeatured ? "Yes" : "No"}</td>
                                    <td>{item.price}</td>
                                    <td>{item.user?.name}</td>
                                    <td>
                                        <Link to={ClientRoute(`trip/${item._id}/images`)}>Upload Images</Link>
                                    </td>
                                    <td>
                                        <a href={`https://tripkarao.pk/trips/${item.slug}`} target="_blank" rel="noopener noreferrer">
                                            Preview Trip
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="container text-center">
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <button type="button" className="btn btn-primary" disabled={this.state.page === 1} onClick={() => this.handlePagination(this.state.page - 1)}>Prev</button>
                            <button type="button" className="btn btn-primary" disabled>Page#{this.state.page}</button>
                            <button type="button" className="btn btn-primary" onClick={() => this.handlePagination(this.state.page + 1)}>Next</button>
                        </div>
                    </div>
                    {/* <Paginate 
                        previousLabel="Prev"
                        nextLabel="Next"
                        breakLabel="..."
                        pageCount={Math.ceil(meta.total / meta.limit)}
                        pageRangeDisplayed={meta.limit}
                        containerClassName="container"
                    /> */}
                </div>
            </>
        )
    }
}

export default GetTrips;