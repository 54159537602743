import React, { Component } from "react";
import ImageUploader from "react-images-upload";
import axios from "axios";
import swal from 'sweetalert';
import ImgCompress from "browser-image-compression";
import { addTrip } from "../../../config/routes/server";

class UploadImages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            defaultImages: []
        }
        this.onDrop = this.onDrop.bind(this);
    }

    componentDidMount() {
        let id = this.props.match.params.id,
        url = `${addTrip}/${id}/admin`;
        console.log("URL", url)
        axios
            .get(url)
            .then(response => {
                this.setState({
                    defaultImages: response.data.images
                })
            })
            .catch(error => {
                swal(error.response.statusText, error.response.data.error, "error");
            });
    }

    onDrop(pictureFiles, pictureDataURLs) {
        var options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }, files = [];
        async function compressImages(images) {
            for(let i = 0; i < images.length; i++) {
                await ImgCompress(images[i], options)
                    .then((compressedFile) => {
                        return files.push(compressedFile);
                    })
                    .catch(error => {
                        console.log(error.message);
                    });
            }
            return await files; 
        }
        compressImages(pictureFiles)
            .then(response => {
                this.setState({
                    images: response
                });
            }) 
        
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let id = this.props.match.params.id;
        const payload = new FormData();
        for (let i = 0; i < this.state.images.length; i++) {
            payload.append(`images`, this.state.images[i]);
        }
        axios
            .put(`${addTrip}/${id}/images`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                  }
            })
            .then(response => {
                swal("Good Job", response.data.success, "success");
            })
            .catch(error => {
                swal(error.response.statusText, error.response.data.error, "error");
            });
    }

    render(){
        return (
            <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">Images Upload</li>
                    </ol>
                </nav>
                <div className="form-group">
                    <ImageUploader
                        withIcon={true}
                        buttonText='Choose images'
                        onChange={this.onDrop}
                        imgExtension={['.jpg', '.jpeg', '.gif', '.png', '.gif']}
                        maxFileSize={10485760 * 2}
                        defaultImages={this.state.defaultImages}
                        withPreview
                        label="Max file size: 20mb, accepted: jpg|jpeg|gif|png"
                    />
                </div>    
                <div className="mt-5 text-right">
                    <button 
                        className="btn w-25 btn-outline-success"
                        onClick={this.handleSubmit}>Submit</button>
                </div>
            </div>
        )
    }


}

export default UploadImages;